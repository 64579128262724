import { render, staticRenderFns } from "./CustomerDetails.vue?vue&type=template&id=4701f6f0&scoped=true&"
import script from "./CustomerDetails.vue?vue&type=script&lang=js&"
export * from "./CustomerDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4701f6f0",
  null
  
)

export default component.exports